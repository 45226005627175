<template>
  <div class="bg-primary-500 text-white py-12">
    <div class="container">
      <div class="grid md:grid-cols-3 gap-12">
        <div>
          <nuxt-link :to="{ name: 'home' }" aria-label="SV Group logo">
            <SpriteSymbol
              name="logo"
              class="h-7 aspect-[73/24]"
              alt="SV Group logo"
            />
          </nuxt-link>
        </div>
        <div>
          <ul class="flex flex-col gap-3 text-base font-regular">
            <li
              v-for="(link, i) in nonGastronomyLinks"
              :key="`global_${i}`"
              class="flex"
              :class="
                link.link?.url?.path ===
                  currentActiveMainLink?.link.url?.path &&
                'font-bold border-l-4 border-l-white pl-3 md:pl-3 -ml-4 md:ml-0'
              "
            >
              <VuepalLink
                :to="link.link?.url?.path"
                class="flex items-center hover:text-primary-300"
              >
                {{ link.link.label }}
              </VuepalLink>
            </li>
            <li
              v-click-away="closeGastronomyDropdown"
              class="flex flex-col overflow-y-hidden transition-all ease-in-out duration-250"
              :style="`--target-height: ${(gastronomyLinks.length + 1) * 50}px`"
              :class="dropdownOpen ? 'h-[var(--target-height)]' : 'h-[42px]'"
            >
              <button
                class="h-[42px] shrink-0 inline-flex gap-0.5 items-center transition-all ease-in-out duration-250 hover:text-primary-300"
                :class="
                  !!activeGastronomyLink &&
                  'font-bold border-l-4 border-l-white pl-3 md:pl-3 -ml-4 md:ml-0'
                "
                @click="toggleGastronomyDropdown"
              >
                <span class="text-left">
                  {{
                    !!activeGastronomyLink
                      ? activeGastronomyLink.link.label
                      : $texts(
                          'header.gastronomyChooseLabel',
                          'Gastronomie: Land wählen',
                        )
                  }}
                </span>
                <SpriteSymbol
                  name="chevron-down"
                  class="size-5 transition-all ease-in-out duration-250 mx-1"
                  :class="dropdownOpen ? 'rotate-180' : ''"
                />
              </button>

              <ul class="flex flex-col mt-2 z-50 gap-3">
                <li
                  v-for="(link, i) in gastronomyLinks.filter(
                    (l) =>
                      !activeGastronomyLink ||
                      l.link?.url?.path !== activeGastronomyLink.link.url?.path,
                  )"
                  :key="`global_gastronomy_${i}`"
                >
                  <VuepalLink
                    :to="link.link.url?.path"
                    class="h-8 flex items-center transition-all ease-in-out duration-250 hover:text-primary-300"
                    @click="closeGastronomyDropdown"
                  >
                    {{ link.link.label }}
                  </VuepalLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <nuxt-link
            :to="contactUrl"
            class="button border-white hover:border-white show-icon"
          >
            {{ $texts('footer.contact', 'Kontakt') }}
            <SpriteSymbol name="paperplane" class="size-5 ml-1" />
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const {
  gastronomyLinks,
  activeGastronomyLink,
  nonGastronomyLinks,
  currentActiveMainLink,
} = await useMainMenuLinks()

const { $texts } = useEasyTexts()
const data = await useInitData()
const dropdownOpen = ref(false)
const contactUrl = data.value.globalConfig.contactUrl?.uri?.path

function openGastronomyDropdown() {
  dropdownOpen.value = true
}

function closeGastronomyDropdown() {
  dropdownOpen.value = false
}

function toggleGastronomyDropdown() {
  if (dropdownOpen.value) {
    closeGastronomyDropdown()
  } else {
    openGastronomyDropdown()
  }
}
</script>
